<template>
    <div class="headerDiv">
        <rxNavBar title="我要报修"></rxNavBar>
        <!--上传图片loading-->
        <loading v-if="loadingFlag"></loading>
        <div class="content">
            <div class="img_group">
                <img src="../../../assets/images/head-quotation-mark.png">
                <div class="clean">我要报修</div>
            </div>
            <!--        图文信息列表-->
            <div class="roomInfoDiv">
                <div class="room_img">
                    <img :src="imgurl!=''&&imgurl!=undefined? imgurl:errorImg" class="room_imgImg">
                </div>

                <div class="address" >
                    <div class="roomAddress">{{name}}</div>
                    <div class="spanDiv">
                        <span>{{address}}</span>
                        <span>|</span>
                        <span>{{area}}m²</span>
                        <span>|</span>
                        <span>{{other}}层</span>
                    </div>

                </div>
            </div>
            <!--    预约时间-->
            <div id="checkInDatePanel" class="part-inputpart-row" @click="clickCheckInDateShow">
                <span :class="isCheckInDatecheckInDateSelect == '' ? 'maker':'orange'" >• </span>
                <span class="time-header">预约时间</span>
                <span class="content-divide">|</span>
                <span class="date-graytext" :class="isCheckInDatecheckInDateSelect ? 'optionSelectedColor':'' ">{{checkInDate}}</span>
                <img class="part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png">
            </div>
            <div>
<!--                <div class="date-dropDownList" v-if="isCheckInDateShow">-->
                    <van-popup v-model="show" position="bottom" :style="{ height: '50%' }" >
                        <van-datetime-picker
                                v-model="currentDate"
                                type="datetime"
                                title="选择年月日"
                                @confirm="checkInDateConfirm"
                                @cancel="checkInDateCancel"
                        />
                    </van-popup>
<!--                </div>-->
            </div>
            <!--        联系人-->
            <div  class="part-inputpart-row">
                <span :class="contact == '' ? 'maker':'orange'" >• </span>
                <span class="header">联系人</span>
                <span class="content-divide">|</span>
                <input class="graytext" v-model="contact" placeholder="填写联系人" />
            </div>
            <!--联系电话-->
            <div  class="part-inputpart-row">
                <span :class="tel == '' ? 'maker':'orange'" >• </span>
                <span class="part-inputpart-row-header">联系电话</span>
                <span class="content-divide">|</span>
                <input type="tel" class="tel_graytext" v-model="tel"  maxlength="11"  placeholder="填写方便联系的电话" />
            </div>
            <!--保洁类型-->
            <div id="cleanId" class="part-inputpart-row" @click="clickCleanTypeShow">
                <span :class="normaltext==''||normaltext==undefined ? 'maker':'orange'" >• </span>
                <span class="part-inputpart-row-header">维修类型</span>
                <span class="divide">|</span>
                <span class="part-inputpart-row-graytext" :class="normaltext!=''&&normaltext!=undefined ? 'optionSelectedColor':'' ">{{normaltext}}</span>
                <img class="part-inputpart-row-right" :class=" isCleanTypeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
            </div>
            <div>
                <!--        可选择的保洁类型选项-->
                <van-popup v-model="isBankTypeSelect" position="bottom">
                    <van-picker show-toolbar  :columns="showType"
                                @cancel="isBankTypeSelect = false" @confirm="selectBankType" />
                </van-popup>
            </div>
            <!--        紧急情况-->
            <div class="normalBlock" >
                <span class="maker" :class="{'orange':(isEmergency||isGenerally)}">•</span>
                <span class="blockTitle">紧急情况</span>
                <div class="rightText">
                    <span class="passText" :class="{'choosedStyle':isEmergency}" @click="chooseEmergency">紧急</span>
                    <span class="content-divide" > | </span>
                    <span class="rejectText" :class="{'choosedStyle':isGenerally}" @click="chooseGenerally">一般</span>
                </div>
            </div>
            <div class="changeCause">
                <div class="changeCauseCentre">
                    <div class="changeCauseCentreTitle">
                        <div class="part-inputpart-row-headerA">维修描述</div>
                        <span class="notRequired">(非必填)</span>
                    </div>
                    <textarea class="textareaInput" v-model="repairDescription"></textarea>
                </div>
            </div>
            <div class="changeCause">
                <div class="changeCauseCentre">
                    <div class="changeCauseCentreTitle">
                        <div class="part-inputpart-row-headerA">上传图片</div>
                        <span class="notRequired">(必填)</span>
                    </div>
                        <van-uploader v-model="fileList" class="uploaderPart" multiple preview-size="90px"
                                      :after-read="afterReadTest" :max-count="10" :before-delete="deleteImage">
                        </van-uploader>
<!--                    </div>-->
                </div>
            </div>
            <van-button :class="!contact || !tel || !isCleanTypeSelect ||!showTime  || !list.length>0||  !checkInDate || !isEmergency&&!isGenerally ?'saveButton_Disable':'saveButton_Enable'"
                        :disabled="!contact || !tel || !isCleanTypeSelect ||!showTime || !list.length>0|| !checkInDate || !isEmergency&&!isGenerally" @click="formSubmit"
            >提交</van-button>
        </div>
    </div>
</template>

<script>
    import {Button, DatetimePicker, DropdownItem, DropdownMenu, NavBar,Popup,Picker,Uploader,} from "vant";
    import {userApplyInit,base64Upload,userRepairApplySave} from "../../../getData/getData";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import Vue from 'vue';
    import {globaluserId, responseUtil} from "../../../libs/rongxunUtil";
    Vue.use(Popup,Picker);
    export default {
        components: {
            [NavBar.name]: NavBar,
            [DropdownMenu .name]:DropdownMenu,
            [DropdownItem .name]:DropdownItem,
            [DatetimePicker.name]: DatetimePicker,
            [Button.name]: Button,
            [Popup.name]: Popup,
            [Picker.name]: Picker,
            [Uploader.name]: Uploader,
            rxNavBar,
        },
        name: "iWantCleaning",
        data(){
            return{
                loadingFlag:false,//上传图片loading
                zone:'沙河口',
                floors:'6层',
                roomAddress:'天通云南  天通云南西区  昌南主路5单元808',
                isCheckInDateShow: false,
                currentDate: new Date(),
                isCheckInDatecheckInDateSelect:false,
                checkInDate:'选择维修时间',
                contact:'',
                tel:'',
                isBankTypeSelectAready: false,
                isBankTypeSelect: false,
                isShow:false,
                normaltext:'',
                selectCleanType: '选择保洁类型',
                isCleanTypeShow: false,
                isCleanTypeSelect: false,
                isEmergency:false,
                 isGenerally:false,
                showTime:false,
                name:'',
                area:'',
                other:'',
                imgurl:'',
                address:'',
                 room_id:'',
                dictionaryTitle:'',//传过来的维修类型
                frontOfCard:'',
                overlay:false,
                cleanType:[],
                showType:[],
                fileList:[],//图片上传列表
                list:[],
                type:'',//保洁类型
                imagesType:false,//图片上传成功标志
                show:false,
                text:'',
                distributionStatus:'',//保洁状态
                repairDescription:'',//维修描述
                errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',
            }
        },
        mounted(){
            this.userApplyInit();
        },
        created() {
            this.room_id=this.$route.query.room_id
            this.normaltext=this.$route.query.dictionaryTitle
            if (this.$route.query.id!=''&&this.$route.query.id!=undefined){
                this.type=this.$route.query.id;
                this.isCleanTypeSelect = true;
            }
        },
        methods:{
            //图片删除时处理
            deleteImage(file){
                for (let i=0;i<this.list.length;i++){
                    if (this.list[i]==file.path){
                        this.list.splice(i,1);
                    }
                }
                return true
            },

            //图片多选上传
            afterReadTest(file){
                // this.loadingFlag = true;
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];
                        this.afterRead(fileDetail,i,fileLength);
                    }
                }else{
                    this.afterRead(file);
                }
            },

            afterRead(file,i,fileLength) {
                let that = this
                let initData = {
                    base64 : file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        file.path=response.data.data.path
                        that.list.push(response.data.data.path)
                        console.log(that.picList)
                        if(i == fileLength){
                            that.loadingFlag = false
                        }
                    })
                })
            },

            selectBankType(item) {
                this.normaltext = item;
                this.isBankTypeSelect = false;
                this.isCleanTypeShow = false
                this.isBankTypeSelectAready = true;
                this.isCleanTypeSelect = true
                for (let i=0;i<this.cleanType.length;i++){//保洁类型装换成数字 用于申请保洁接口使用
                    if (item==this.cleanType[i].dictionaryTitle) {
                        this.type=this.cleanType[i].id
                    }
                }
            },
            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },
            //返回上一层
            leftReturnA(){
                this.$router.go(-2);
            },
            // 事件选择事件
            clickCheckInDateShow() {
                this.show=true
                this.isCheckInDateShow = !this.isCheckInDateShow;
                var panel = document.getElementById('checkInDatePanel')
                if(panel){
                    document.addEventListener('click',e =>{
                        if(!panel.contains(e.target)){
                            this.isCheckInDateShow = false
                        }
                    })
                }
            },
            // 格式化日期方法
            formatDate(dateTime) {
                console.log(dateTime.getMinutes())
                let year = `${dateTime.getFullYear()}`;
                let month  = `${dateTime.getMonth() + 1}` ;
                let date = `${dateTime.getDate()}` ;
                let hours = `${dateTime.getHours()}` ; ;
                let minutes = `${dateTime.getMinutes()}` ; ;
                if(month < 10){
                    month = `0${dateTime.getMonth() + 1}`
                }
                if(date < 10){
                    date = `0${dateTime.getDate()}`
                }
                if(hours < 10){
                    hours = `0${dateTime.getHours()}`
                }
                if( minutes < 10){
                    minutes = `0${dateTime.getMinutes()}`
                }
                return year+ '/' + month+ '/' + date + ' ' + hours + ':' + minutes;

            },
            //日期选择组件 点击确认键后执行方法
            checkInDateConfirm(value){
                this.checkInDate= this.formatDate(value)
                this.time =  this.formatDate(value)
                if (this.time!=''){
                    this.showTime=true
                }
                // this.isCheckInDateShow = false
                this.show=false
               this.isCheckInDatecheckInDateSelect = true
            },
            //日期选择组件 点击取消键后执行方法
            checkInDateCancel(){
                this.show=false
                this.isCheckInDateShow = false
            },

            // 维修类型组件
            clickCleanTypeShow() {
                this.isCleanTypeShow = true
                this.isBankTypeSelect = !this.isBankTypeSelect;
            },
            formSubmit:function(){
                //姓名校验
                let that = this;
                if(that.contact==''||that.contact==undefined){
                    responseUtil.alertMsg(that,"姓名不能为空")
                    this.personisnull=false
                }else{
                    //姓名汉字正则校验
                    var mobileA = /^[\u2E80-\u9FFF]+$/;
                    var contact=that.contact.trim();
                    let isMobileA =  mobileA.exec(contact);
                    if(!isMobileA){
                        responseUtil.alertMsg(that,"请输入正确的姓名")
                    }else{
                        //联系校验
                        if(that.tel==''||that.tel===undefined){
                            responseUtil.alertMsg(that,"联系方法不能为空")
                        }else{
                            var mobile = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                            let isMobile =  mobile.exec(that.tel);
                            if(!isMobile){
                                responseUtil.alertMsg(that,"请输入正确的电话号码")
                            }else{
                                that.userRepairApplySave();//调用接口
                            }
                        }
                    }
                }
            },
            // 紧急、一般按钮
            chooseEmergency() {
                this.distributionStatus="0"
                this.isEmergency = true;
                this.isGenerally = false;
            },
            chooseGenerally() {
                this.distributionStatus="1"
                this.isGenerally = true;
                this.isEmergency = false;

            },

            userApplyInit(){//维修初始化接口
                let that =this;
                let _parms = {
                    user_id:globaluserId(),
                    room_id:that.room_id,
                    type:'1'
                }
                userApplyInit(_parms).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code==0){
                           that.name= response.data.data.roomFuzzyAddress,       //房源模糊地址
                           that.area=response.data.data.roomArea,                //房源面积
                           that.other=response.data.data.roomFloor,             //房源楼层第几层，暂无总层数
                           that.imgurl=response.data.data.roomPhotoPath,      //房源图片列表
                           that.address=response.data.data.roombusinessCircleName,      //城区
                           that.room_id=response.data.data.room_id
                           that.cleanType=response.data.data.dictionaryList.repairType//字典表数据
                            for (let i=0;i<that.cleanType.length;i++){
                                that.showType.push(that.cleanType[i].dictionaryTitle)
                            }
                        }else if (response.data.code != 0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else{
                            responseUtil.alertMsg(that,response.data.msg)
                        }
                    })
                })
            },

            userRepairApplySave(){//维修申请
                let that =this;
                let _parms = {
                    user_id:globaluserId(),
                    room_id:that.room_id,
                    appointmentTime:new Date(that.time),
                    linkManName:that.contact,
                    linkManMobile:that.tel,
                    repairType:that.type,
                    repairDescription:that.repairDescription,
                    repairPhotoPath:that.list,//维修图片
                    repairStatus:that.distributionStatus//维修紧急状态
                }
                userRepairApplySave(_parms).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    if(response.data.code==0){
                     that.leftReturnA()
                    }else if (response.data.code != 0){
                        responseUtil.alertMsg(that,response.data.msg)
                    }else{
                        responseUtil.alertMsg(that,response.data.msg)
                    }

                })
            })
    },
        }
    }
</script>

<style scoped>
    .headerDiv{
        width: 100%;
        height: auto;
        overflow: hidden;

    }
    .part-inputpart-cardpic-pic {
        width: 85.6px;
        height: 54px;
        border-radius: 5px;
        margin: 0 15px;
    }
    .uploaderPart{
        margin:10px 0px 0px 20px;

    }
    .part-inputpart-cardpic-button {
        /*display: inline-block;*/
        /*background: url("../../../assets/images/grayAdd.png") center center no-repeat;*/
        width: 80px;
        height: 80px;
        border-radius: 8px;

        background-color: #fafafa;

    }
    .content-have {
        padding: 3px;
        margin: 0 15px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }
    .pOne{
        font-size: 15px;
        font-weight: bold;
    }
    .content-none {
        padding: 3px;
        margin: 0 15px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .changeCause{
        width: 100%;
        margin-top: 15px;
    }
    .textareaInput{
        display: block;
        width: 90%;
        margin: 9px auto 0px;
        height: 90px;
        border-radius: 8px;
        background-color: #f7f7f7;
        border: none;
        resize: none;
        /*padding: 15px;*/
        font-size: 14px;
    }
    .changeCauseCentre{
        margin: 0 auto;
        width: 92%;
        background-color: #FFFFFF;
        padding: 10px 0px;
        border-radius: 8px;
    }
    .changeCauseCentreTitle{
        width: 92%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
    }

    .content{
        margin-top: 7px;
    }

    img{
        width: 51.48px;
        height: 62.4px;
    }
    .img_group{
        height: 75px;
        margin-top: 10px;
    }
    .clean{
        width: 100%;
        /*width: 80px;*/
        height: 28px;
        color: rgba(34, 34, 34, 1);
        font-size: 20px;
        text-align: left;
        font-family: PingFangSC-Semibold;
        position: relative;
        font-weight: bold;
        left: 24px;
        bottom: 40px;
    }
    .roomInfoDiv{
        width: 345px;
        /*float: left;*/
        /*position: relative;*/
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        height: 100px;
        border-radius: 8px;
        margin: 0 5px 15px 15px;
    }
    .room_img{
        width: 100px;
        height: 80px;
      border-radius: 8px;
        margin: 0 10px 0 10px;
    }
    .room_imgImg{
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
    .address{
        /*width: 235px;*/
        display: flex;
        flex-direction: column;
        /*align-items: center;*/
        height: 80px;
        justify-content: left;
        justify-content: left;
        justify-items: left;
    }
    .roomAddress{
       font-size: 13px;
        /*height: 20px;*/
        width: 218px;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .spanDiv {
        line-height: 15px;
        /*margin-top: 5px;*/
    }
    .spanDiv >span :not(:nth-of-type(1)){
        /*margin-left: 10px;*/
    }
    .spanDiv span {
        color: gray;
        margin-right: 8px;
        font-size: 11px;
    }
    .part-inputpart-row {
        width: 345px;
        float: left;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        height: 50px;
        border-radius: 8px;
        margin: 0 5px 15px 15px;
    }
    /*圆点样式---灰色*/
    /*.maker{*/
    /*    color: #999999;*/
    /*    float: left;*/
    /*    font-size: 28px;*/
    /*    margin: 10px 10px 10px 15px;*/
    /*}*/
    .maker{
        color: #999999;
        font-size: 28px;
        margin: 0px 10px 0px 15px;
    }
    /*圆点样式---橘色*/
    .orange{
        color: #ff5809;
        float: left;
        font-size: 28px;
        margin: 10px 10px 10px 15px;
    }
    .part-inputpart-row-right-timepick{
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
    .part-inputpart-row-header{
        font-weight: bold;
        font-size: 14px;
        width: 95px;
        color: black;
    }
    .part-inputpart-row-headerA{
        font-weight: bold;
        font-size: 14px;
        color: black;
    }
    .notRequired{
        color: rgba(153, 153, 153, 1);
        font-size: 8px;
        font-family: PingFangSC-Regular;
    }
    .time-header{
        font-weight: bold;
        font-size: 14px;
        width: 100px;
        color: black;
    }
    .header{
        font-weight: bold;
        font-size: 14px;
        width:80px;
        color: black;
    }
    input::-webkit-input-placeholder{
        color: #d9d9d9;
    }
    /*竖线样式*/
    .content-divide {
        font-size: 14px;
        color: #efefef;
        margin:0 7px 0 7px;
    }
    .divide {
        font-size: 14px;
        color: #efefef;
        margin:0 7px 0 10px;
    }
    .date-graytext {
        color: #d9d9d9;
        /*color: #ff5809;*/
        width: 100%;
        font-size: 14px;
        border: none;
        margin-top: 1px;
    }
    .date-dropDownList{
        margin-left: 15px;
        width: 92%;
        margin-right: 10px;
        position: absolute;
        z-index: 99;
        margin-top: 53px;
    }
    .part-inputpart-row-normaltext{
        color: #ff5809;
    }
    /*选择三角图标的向上向下样式*/
    .part-inputpart-row-right-upArrow{
        width: 9px;
        height: 9px;
        transform: rotateX(180deg);
    }
    .part-inputpart-row-right-downArrow{
        width: 9px;
        height: 9px;
    }
    /*下拉菜单选项样式*/
    .part-inputpart-dropDownList-option{
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: black;
        float: left;
        width: 345px;
        height: 35px;
        padding-top: 10px;
        margin-left: 15px;
    }
    .dropDownList{
        width: 100%;
        position: absolute;
        z-index: 99;
        margin-top: 245px;
    }
    .part-inputpart-row-right{
        float: right;
        margin-right: 10px;
        font-size: 14px;
        color: #D8D8D8;
    }
    .part-inputpart-row-graytext {
        color: #d9d9d9;
        width: 100%;
        font-size: 14px;
    }
    .graytext {
        color:black;
        width: 100%;
        font-size: 14px;
        margin-top: 1px;
        border: none;
    }
    .tel_graytext {
        width: 100%;
        font-size: 14px;
        border: none;
        margin-top: 4px;
    }
    .rightText{
        display: flex;
        margin-left:auto;
        font-size: 14px;
        color: #999999;
    }
    .choosedStyle{
        color: #ff3c00;
        font-weight: bold;
    }
    .passText{
        flex: 1;
        padding-right: 7px;
        padding-top: 2px;
    }
    .rejectText {
        padding-left: 7px;
        padding-right: 33px;
        padding-top: 2px;
    }
    .normalBlock{
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        position: relative;
        background-color: white;
        line-height: 1rem;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 10px 15px 0 15px;
    }
    .blockTitle{
        float: left;
        margin-right: 25px;
        margin-top: 2px;
        font-size: 14px;
        font-weight: bold;
        width: 35%;
        color: black;
    }
    .optionSelectedColor{
        color: #ff5d3b;
    }
    /*保存按钮不可点击样式*/
    .saveButton_Disable{
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 45px;
        border-radius: 8px;
        margin:50px 50px 15px 15px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }
    .saveButton_Enable{
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 45px;
        border-radius: 8px;
        margin:50px 50px 15px 15px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;
    }
    .van-uploader__preview{

    }
</style>
